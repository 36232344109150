import { lazy, Suspense } from 'react';

import 'dayjs/locale/pl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { plPL } from '@mui/x-date-pickers/locales';

import { useAgreements } from './hooks/useAgreements';
import { Loader } from './components';

const LoanForm = lazy(() => import('./layout/LoanForm'));

export default function App() {
    const { agreements, requiredAgreementsIds } = useAgreements();

    const AgreementsLoaded = agreements.length > 0;

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='pl'
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            {AgreementsLoaded
                ? (
                    <Suspense fallback={<Loader />}>
                        <LoanForm agreements={agreements} requiredAgreementsIds={requiredAgreementsIds} />
                    </Suspense>
                ) : <Loader />
            }
        </LocalizationProvider>
    );
}
