import { FC } from 'react';

import Modal, { modalClasses } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CommonProps } from '../layout/LoanForm';
import logo from '../assets/images/logo.svg';
import { Divider, Clock, Logo } from './index';

const P = styled('p', { name: 'FinishModalP' })(({ theme }) => ({
    fontSize: '1.4rem',
    fontWeight: 300,
    lineHeight: '125.8%',
    margin: 0,
    padding: '2rem 0 0 0',

    [theme.breakpoints.up('sm')]: {
        fontSize: '2.4rem',
        lineHeight: 1,
    },

    [theme.breakpoints.up('lg')]: {
        fontSize: '3rem',
    }
}));

const H3 = styled('h3', { name: 'FinishModalH3' })(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 300,
    lineHeight: '125.8%',
    margin: '6rem 0 2rem 0',

    [theme.breakpoints.up('sm')]: {
        fontSize: '3rem',
        lineHeight: 1,
        textAlign: 'center',
    },

    [theme.breakpoints.up('lg')]: {
        fontSize: '3.6rem',
    }
}));

interface Props {
    open: boolean;
    /*handleClose: () => void;
    onAccept: () => void;*/
}

const FinishModal: FC<CommonProps & Props> = props => {
    const {
        t,
        open,
    } = props;

    return (
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={theme => ({
                [`.${modalClasses.backdrop}`]: {
                    bgcolor: 'var(--modal-bg-color)',
                    backdropFilter: 'blur(7.5px)',

                    /*[theme.breakpoints.up('lg')]: {
                        width: '118rem',
                        minHeight: '65rem',
                        height: '65rem',
                        margin: 'auto',
                        borderRadius: '1rem',
                    }*/
                },
            })}
        >
            <Box
                sx={theme => ({
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'scroll',
                    maxHeight: '100vh',
                    width: '100%',
                    height: '100%',
                    bgcolor: 'transparent',
                    color: 'var(--input-color)',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: '0 3rem',

                    [theme.breakpoints.up('sm')]: {
                        p: '0 4rem',
                    },

                    /*[theme.breakpoints.up('lg')]: {
                        maxHeight: '65rem',
                        width: '118rem',
                        height: '65rem',
                        margin: 'auto',
                        borderRadius: '1rem',
                        p: 0,
                        overflow: 'hidden',
                    }*/
                })}
            >
                <Logo sx={theme => ({
                    [theme.breakpoints.up('sm')]: {
                        margin: '5rem auto 0'
                    },
                    /*[theme.breakpoints.up('lg')]: {
                        display: 'none',
                    }*/
                })} />
                <Divider role="presentation" sx={theme => ({
                    /*[theme.breakpoints.up('lg')]: {
                        display: 'none',
                    }*/
                })}/>
                <Box sx={theme => ({
                    mt: '1.5rem',

                    [theme.breakpoints.up('sm')]: {
                        mt: '4rem',
                        mx: 'auto',
                        maxWidth: '64rem',
                    },

                    [theme.breakpoints.up('md')]: {
                        maxWidth: '68rem',
                    }
                })}>
                    <H3 sx={{ textWrap: 'nowrap' }}>
                        <b>{t.finishModal.thx}</b>
                    </H3>
                    <P>
                        {t.finishModal.now}
                    </P>
                    <P>
                        <b>{t.finishModal.dontClose}</b>
                    </P>
                    <Clock isActive={open} time={180} />
                </Box>
            </Box>
        </Modal>
    );
}

export default FinishModal;
