import { FC } from 'react';
import { useFormState } from 'informed';

import Modal, { modalClasses } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

import { CommonProps } from '../../layout/LoanForm';
import { AgreementsOutput } from '../../hooks/useAgreements';
import logo from '../../assets/images/logo.svg';
import { Divider, Logo, StepNav, AgreementsCheckboxesSection } from '../index';


const P = styled('p', { name: 'AgreeP' })(({ theme }) => ({
    fontSize: '1.4rem',
    fontWeight: 300,
    lineHeight: '125.8%',
    margin: 0,

    [theme.breakpoints.up('sm')]: {
        fontSize: '2.4rem',
        lineHeight: 1,
    }
    // color: #DDD;
    // font-family: Roboto;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 300;
    // line-height: 125.8%; /* 17.612px */
}));

interface UniqueProps {
    open: boolean;
    handleClose: () => void;
    onAccept: () => void;
}

const AgreementsModal: FC<CommonProps & AgreementsOutput & UniqueProps> = props => {
    const {
        t,
        open,
        agreements,
        requiredAgreementsIds,
        onAccept,
        handleClose
    } = props;

    const { valid } = useFormState();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={theme => ({
                [`.${modalClasses.backdrop}`]: {
                    bgcolor: 'var(--modal-bg-color)',
                    backdropFilter: 'blur(7.5px)',

                    [theme.breakpoints.up('lg')]: {
                        width: '118rem',
                        minHeight: '65rem',
                        height: '65rem',
                        margin: 'auto',
                        borderRadius: '1rem',
                    }
                },
            })}
        >
            <Box
                sx={theme => ({
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'scroll',
                    maxHeight: '100vh',
                    width: '100%',
                    height: '100%',
                    bgcolor: 'transparent',
                    color: 'var(--input-color)',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: '0 3rem',

                    [theme.breakpoints.up('sm')]: {
                        p: '0 4rem',
                    },

                    [theme.breakpoints.up('lg')]: {
                        maxHeight: '65rem',
                        width: '118rem',
                        height: '65rem',
                        margin: 'auto',
                        borderRadius: '1rem',
                        p: 0,
                        overflow: 'hidden',
                    }
                })}
            >
                <Logo sx={theme => ({
                    [theme.breakpoints.up('sm')]: {
                        margin: '5rem auto 0'
                    },
                    [theme.breakpoints.up('lg')]: {
                        display: 'none',
                    }
                })} />
                <Divider role="presentation" sx={theme => ({
                    [theme.breakpoints.up('lg')]: {
                        display: 'none',
                    }
                })}/>
                <Box sx={theme => ({
                    mt: '1.5rem',
                    [theme.breakpoints.up('sm')]: {
                        mt: '4rem',
                    },
                    [theme.breakpoints.up('lg')]: {
                        mt: '0',
                        p: '4rem',
                        overflow: 'auto',
                        height: '100%',
                    }

                })}>
                    <P sx={{ mb: '4rem' }}>
                        {t.basicStep.agreementInfo1}
                    </P>
                    <P>
                        <b>{t.basicStep.agreementImportant}</b> {t.basicStep.agreementInfo2}
                    </P>

                    <StepNav
                        sx={theme => ({
                            margin: '3rem auto',

                            [theme.breakpoints.up('sm')]: {
                                margin: '4rem auto',
                            },
                        })}
                        t={t}
                        hideBack
                        disabled={!valid}
                        onNext={() => onAccept()}
                        customNextText={t.basicStep.agreementConfirm}
                    />

                    <AgreementsCheckboxesSection
                        t={t}
                        agreements={agreements}
                        requiredAgreementsIds={requiredAgreementsIds}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default AgreementsModal;