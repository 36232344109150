import { FC, useState } from 'react';
import parse from 'html-react-parser'
import { styled } from '@mui/material/styles';
import { CommonProps } from '../../layout/LoanForm';

const Toggle = styled('span', { name: 'funedaToggle' })(({ theme }) => ({
    fontWeight: 'bold',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
        fontSize: '1.7rem'
    },
}))

const Root = styled('span', { name: 'funedaLabelToggle' })`
  display: block;
  cursor: pointer;
  flex-grow: 1;
  
  label {
    padding-top: 1rem;
    cursor: inherit;
    
    ${({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        //fontSize: '1.5rem'
      },
    })}
  }
`

const LabelToggle: FC<CommonProps & {targetId: string, bold?: boolean, content: string, required?: boolean}> = props => {
    const {
        targetId,
        t,
        bold,
        content,
        required
    } = props;

    const [isToggled, toggle] = useState<boolean>(false);

    const [excerpt, continuation] = content.split('--MORE--');
    const isLong = !!continuation;
    const labelContent = parse(`${required ? '*' : ''}${isLong && !isToggled ? `${excerpt}...` : `${excerpt}${isLong ? ' ' + continuation : ''}` }`);

    return (
        <Root>
            <label htmlFor={targetId}>{bold ? <b>{labelContent}</b> : labelContent}</label>{isLong ? ' ' : ''}
            {isLong && (
                <Toggle
                    onClick={() => toggle(!isToggled)}
                    role='button'
                >
                    {t.basicStep[isToggled ? 'collapse' : 'expand']}
                </Toggle>
            )}
        </Root>
    )
}

export default LabelToggle;

