// @ts-nocheck

import { FC } from 'react';
import { useFormState, useMultistepState } from 'informed';

import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import MuiLinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { FieldMap, FieldsNotQualifiedForProgress } from '../model/FieldMap';
import INITIAL_FORM_STATE, {
    addressInformation, AddressInformationStep,
    basicInformation, BasicInformationStep, EmployeeInformationStep,
    financeInformation, FinanceInformationStep, LoanFormValues,
    personalInformation, PersonalInformationStep, employeeInformation
} from '../model/formState';

const NumberProgress = styled('span', { name: 'numberProgress' })(({ theme }) => ({
    display: 'block',
    alignSelf: 'flex-end',
    textAlign: 'right',
    color: 'rgba(250, 250, 250, 0.90);',
    fontSize: '1.5rem',

    [theme.breakpoints.up('sm')]: {
        marginBottom: 15,
        fontSize: 24,
        lineHeight: 1.1,
    }
}));

const LinearProgress = styled(MuiLinearProgress, { name: 'linearProgress' })(({ theme }) => ({
    height: '7px',
    borderRadius: '4px',
    backgroundColor: 'rgba(133, 133, 133, 0.50)',
    mb: '.8rem',

    [theme.breakpoints.up('sm')]: {
        mb: 0,
    },

    [`.${linearProgressClasses.bar1Buffer}`]: {
        backgroundColor: '#DDD',
    },
    [`.${linearProgressClasses.bar2Buffer}`]: {
        backgroundColor: '#B7B7B7',
    },
    [`.${linearProgressClasses.dashed}`]: {
        backgroundImage: 'none',
    }
}));

const progressQualifiedFields = Object.values(FieldMap).filter(name => !FieldsNotQualifiedForProgress.includes(name));
const ratio = numerator => Number(((numerator.length / progressQualifiedFields.length) * 100).toFixed(0))
const steps = Object.keys(INITIAL_FORM_STATE);

const { beforeAgreements, ...rest } = basicInformation as BasicInformationStep;
const initialFlatStepsState = {
    basicInformation: {
        ...beforeAgreements,
    },
    personalInformation,
    addressInformation,
    financeInformation,
    employeeInformation
}

const ProgressWrapper: FC = () => {
    const { values, errors } = useFormState();
    const { current } = useMultistepState();

    const bufferQualifiedStepsKeys = steps.slice(0, steps.indexOf(current) + 1);
    const flatTouchedStepsFields = bufferQualifiedStepsKeys.flatMap(stepKey => Object.keys(initialFlatStepsState[stepKey]))

    const { beforeAgreements, ...rest } = values.basicInformation || {} as BasicInformationStep;
    const { license_plate_number, apartment_number, work_seniority, ...flatFormState } = values ? {
        ...beforeAgreements,
        ...values.personalInformation as PersonalInformationStep,
        ...values.addressInformation as AddressInformationStep,
        ...values.financeInformation as FinanceInformationStep,
        ...values.employeeInformation as EmployeeInformationStep,
     } : {}

    const filled = progressQualifiedFields.filter(
        (fieldName) => flatFormState[fieldName] /*&& !errors[fieldName]*/
    );
    const progress = ratio(filled);
    const buffer = ratio(flatTouchedStepsFields);

    return (
        <>
            <NumberProgress>{progress}%</NumberProgress>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={theme => ({
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        maxWidth: 374,
                    },
                    [theme.breakpoints.up('md')]: {
                        maxWidth: 499,
                    },
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: 500,
                    }
                }) }>
                    <LinearProgress
                        variant="buffer"
                        value={progress}
                        valueBuffer={buffer}
                    />
                </Box>
            </Box>
        </>
    )
}

export default ProgressWrapper;