import { Dispatch, FC, SetStateAction } from 'react';
import { StepNav } from '../index';
import { useScopedState } from 'informed';
import { CommonProps } from '../../layout/LoanForm';
import { BeforeAgreementsScope } from '../../model/formState';

const AgreementsHalfStepNav: FC<
    CommonProps &
    { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>|Function }
> = props => {
    const {
        t,
        open: openAgreementsModal,
        setOpen: setOpenAgreementsModal
    } = props;

    const { valid: scopeValid } = useScopedState() as { valid: boolean, value: BeforeAgreementsScope };

    const onNext = () => {
        if (scopeValid) {
            setOpenAgreementsModal(true);
        }
    };

    return (
        <StepNav t={t} hideBack onNext={onNext} disabled={openAgreementsModal} />
    );
}

export default AgreementsHalfStepNav;
