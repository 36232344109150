import { FC } from 'react';
import { useField } from 'informed';

import InputBase, { inputBaseClasses } from '@mui/material/InputBase';
import { paperClasses } from '@mui/material/Paper';
import MuiMenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
import MuiSelect, { selectClasses } from '@mui/material/Select';
import SvgIcon from '@mui/material/SvgIcon';
import { listClasses } from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { FormControlStyled } from '../index';
import { CommonProps } from '../../layout/LoanForm';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '../Tooltip/Tooltip';


const FunedaInputBase = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(2),
    },

    color: 'inherit',
    padding: '0 0 0 3rem',
    fontSize: '1.4rem',
   /* borderBottomWidth: 0,*/

    /*['&:before']: {
        borderBottomWidth: 0
    },*/

    [`& .${inputBaseClasses.input}.${selectClasses.select}`]: {
        color: 'inherit',
        // padding: '0 0 0 3rem',
        fontSize: '1.4rem',
        // lineHeight: 1,
        height: '1.8rem',
        minHeight: 'auto',
        padding: '0.2rem 0 .6rem',

        [theme.breakpoints.up('sm')]: {
            padding: '1.2rem 0 1.4rem',
            fontSize: '2rem',
        },

        /*'&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },*/
    },

}));

const FunedaMenuItem = styled(MuiMenuItem)(({ theme }) => ({
    ['&:not(:last-child):after']: {
        position: 'absolute',
        bottom: 0,
        display: 'block',
        content: `" "`,
        width: '100%',
        height: '1px',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 48.78%, rgba(255, 255, 255, 0.00) 92.53%)',
    }
}));

interface Props {
    id?: string,
    name: string,
    label: string,
    tooltipText?: string,
    options: Array<{ value: number|string, label: string }>,
    required?: boolean,
    validateWhen?: string[];
    // value: string,
    //onChange: (SelectChangeEvent) => void
}

// @ts-ignore
const dropdownIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
        <path d="M1 1L7.5 6.86957L14 1" stroke="#DDDDDD" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

/*const dropdownIconTablet = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="13" viewBox="0 0 25 13" fill="none">
        <path d="M1 1.16669L12.0741 11.1667L23.1481 1.16669" stroke="#DDDDDD" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
    </svg>
);*/

const Select: FC<CommonProps & Props> = props => {
    const {
        t,
        id: propsId,
        label,
        tooltipText,
        name,
        options,
        tabindex,
        // validateWhen,
        // value,
        //onChange
    } = props;

    const id = propsId || name

    const { fieldState, fieldApi, render } = useField({
        type: 'select',
        ...props
    });

    // The field state ( contains value, error, dirty.. etc)
    const { value } = fieldState;

    // The field api ( allows you to control the field )
    const { setValue, setTouched } = fieldApi;

    // @ts-ignore
    return (
        <FormControlStyled id={id || name} label={label}>
            {render(
                <MuiSelect
                    IconComponent={() => (
                        <SvgIcon
                            sx={theme => ({
                                position: 'absolute',
                                color: 'var(--grey-221)',
                                width: '1.3rem',
                                right: '3.7rem',
                                top: '-.4rem',

                                pointerEvents: 'none',

                                [theme.breakpoints.up('sm')]: {
                                    width: '2.2rem',
                                    right: '3.5rem',
                                    top: '.5rem',
                                },

                            })}
                        >{dropdownIcon}</SvgIcon>
                    )}
                    name={name}
                    // value={(value || '').toString()}
                    label={label}
                    onChange={event => {
                        setValue(event.target.value)
                    }}
                    id={id || name}
                    value={!value && value !== 0 ? '' : value}
                    input={<FunedaInputBase />}
                    inputProps={{
                        ...(tabindex && { tabIndex: Number(tabindex) })
                    }}
                    onBlur={e => {
                        setTouched(true, e);
                    }}
                    MenuProps={{ sx: theme => ({
                        [`.${paperClasses.root}`]: {
                            background: 'var(--modal-bg-color)',
                            color: 'var(--input-color)',

                            [theme.breakpoints.up('sm')]: {
                                // padding: '1.2rem 0 1.4rem',
                                fontSize: '2rem',
                            },
                        },

                        [`.${listClasses.root}`]: {
                            p: 0,
                            fontSize: '1.4rem',

                            [theme.breakpoints.up('sm')]: {
                                fontSize: '2rem',
                            },
                        },

                        [`.${buttonBaseClasses.root}.${menuItemClasses.root}`]: {
                            padding: '1rem 2rem',
                            fontSize: 'inherit',
                        },

                        [`.${menuItemClasses.focusVisible}`]: {
                            backgroundColor: 'rgba(221, 221, 221, 0.06)',
                        }
                    })}}
                    {...(tooltipText && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip text={tooltipText}/>
                            </InputAdornment>
                        )
                    })}
                >
                    <FunedaMenuItem value={''}><em>{t.common.none}</em></FunedaMenuItem>
                    {options.map(({ label, value }, index) => (
                        <FunedaMenuItem key={index} value={value}>{label}</FunedaMenuItem>
                    ))}
                </MuiSelect>
            )}
        </FormControlStyled>
    )
}

export default Select;
