import { createTheme } from '@mui/material/styles';

import { plPL as corePlPL } from '@mui/material/locale';
import { plPL } from '@mui/x-date-pickers/locales';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: false;
    }
}

// A custom theme for this app
const theme = createTheme(
    {
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1260,
                // xl: 1536,
            },
        },
        typography: {
            htmlFontSize: 10,
        },
        palette: {
            primary: {
                main: '#DDD',
                // #B7B7B7 ciemniejszy slider
                // rgba(133, 133, 133, 0.50); tło slidera
            },
            /*secondary: {
                main: '#19857b',
            },
            error: {
                main: red.A400,
            },*/
        },
    },
    plPL,
    corePlPL,
);

export default theme;
