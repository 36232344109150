import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";

const StyledTimer = styled('div')(({ theme }) => ({
    fontSize: '4rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 600,
    lineHeight: '125.8%',
    margin: '6rem 0 2rem 0',
}));

function format(time) {
    let seconds: string | number = time % 60;
    let minutes: string | number = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ':' + seconds;
}

interface Props {
    isActive: boolean;
    time: number;
}

const Clock = ({ isActive, time }: Props) => {
    const [currentTime, setCurrentTime] = useState(time);

    useEffect(() => {
        let intervalId;

        if (isActive) {
            intervalId = setInterval(() => {
                setCurrentTime(prevTime => Math.max(0, prevTime - 1));
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isActive]);

    const formattedTime = format(currentTime);

    return (
        <div>
            <StyledTimer>{formattedTime}</StyledTimer>
        </div>
    );
};

export default Clock;
