import { FC } from 'react';

import { TextField } from './index';
import { FieldMap } from '../model/FieldMap';
import { styled } from '@mui/material/styles';
import { CommonProps } from '../layout/LoanForm';
import { defaultBankAccountCountry } from '../model/formState';
import { validateFunc } from '../helper';

const Root = styled('div', { name: 'funedaMobilePhone' })`
  display: grid;
  grid-template-columns: 4rem 1fr;
  column-gap: .5rem;
  
  ${({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '5.2rem 1fr',
        columnGap: '.7rem',
    },
    
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '5.5rem 1fr',
    }
  })}
`;

const isValidBankAccountNumber = (value: string) => {
    if (value.length === 26) {
        const accountNumber = value.slice(2, 27);
        const modifiedAccountNumber = accountNumber.concat('2521', value[0], value[1]);
        const modifiedAccountNumberChunks = [modifiedAccountNumber.slice(0, 10), modifiedAccountNumber.slice(10, 20), modifiedAccountNumber.slice(20, 30)];
        //@ts-ignore
        return ((modifiedAccountNumberChunks[0] % 97 + modifiedAccountNumberChunks[1]) % 97 + modifiedAccountNumberChunks[2]) % 97 === 1;
    }

    return false;
};

const BankAccount: FC<CommonProps> = props => {
    const {
        t,
    } = props;

   /* const { render, informed } = useField({
        type: 'text',
        ...props
    });*/

    return (
        <Root>
            <TextField
                name={FieldMap.BANK_ACCOUNT_COUNTRY}
                placeholder={defaultBankAccountCountry}
                //@ts-ignore
                sxModifier="bankAccountPrefix"
                maxLength="2"
                readOnly
                required
                tabindex="-1"
            />
            <TextField
                name={FieldMap.BANK_ACCOUNT_NUMBER}
                label={t.financeStep.bank_account_number}
                tooltipText={t.financeStep.bank_account_number_tooltip}
                validate={value => validateFunc(value, isValidBankAccountNumber, t.common.valueError)}
                maxLength="26"
                inputMode="numeric"
                formatter={Array(26).fill('#').join('')} //'***######'
                required
                autoComplete='cc-number'
            />
        </Root>
    )
}

export default BankAccount;
