enum ScopeMap {
  BEFORE_AGREEMENTS = 'beforeAgreements',
}

enum FieldMap {
  AMOUNT = 'amount',
  PERIOD = 'period',
  EMAIL = 'email', //bounce email
  PHONE_COUNTRY_PREFIX = 'phone_country_prefix', //divide numbers add +34
  PHONE_MOBILE = 'phone_mobile', //divide numbers add +34
  PESEL = 'pesel', //custom validation
  LOAN_REASON = 'loan_reason',
  AGREEMENTS = 'agreements',


  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  /*GENDER = 'gender',
  DATE_OF_BIRTH = 'date_of_birth',*/
  ID_CODE = 'id_code', //custom validation
  ID_CARD_NUMBER_ISSUED = 'id_card_number_issued',
  MARITAL_STATUS = 'marital_status',
  HAS_DEBT = 'has_debt',
  AMOUNT_OF_CHILDREN = 'amount_of_children',

  TEXT_CITY = 'text_city',
  TEXT_ZIP = 'text_zip',
  STREET = 'street',
  HOUSE_NUMBER = 'house_number',
  APARTMENT_NUMBER = 'apartment_number',
  HOME_PROPERTY_OWNERSHIP = 'home_property_ownership',
  HOME_PROPERTY_TYPE = 'home_property_type',
  HOME_PROPERTY_RESIDENCY_START_DATE = 'home_property_residency_start_date',

  EDUCATION = 'education',
  INCOME_SOURCE = 'income_source',
  INCOME = 'income',
  HOME_INCOME_VALUE = 'home_income_value',
  HOME_MONTHLY_EXPENSES = 'home_monthly_expenses',
  CAR_STATUS = 'car_status',
  LICENSE_PLATE_NUMBER = 'license_plate_number',
  BANK_ACCOUNT_COUNTRY = 'bank_account_country',
  BANK_ACCOUNT_NUMBER = 'bank_account_number',
  WORK_COMPANY_NAME = 'work_company_name',

  EMPLOYEE_NIP = "employee_nip",
  EMPLOYEE_ZIP = "employee_zip",
  EMPLOYEE_CITY = "employee_city",
  EMPLOYEE_STREET = "employee_street",
  INDUSTRY = "industry",
  OCCUPATION = "occupation",
  WORK_SENIORITY = "work_seniority",
}

const FieldsNotQualifiedForProgress = [
    FieldMap.AGREEMENTS,
    FieldMap.LICENSE_PLATE_NUMBER,
    FieldMap.APARTMENT_NUMBER,
    FieldMap.WORK_SENIORITY
]

export { FieldMap, FieldsNotQualifiedForProgress, ScopeMap };
