import { FC, PropsWithChildren } from 'react';

import FormControl, { formControlClasses } from '@mui/material/FormControl';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import { FormHelperText, Theme } from '@mui/material';


interface Props {
    id: string,
    name?: string,
    label: string,
    error?: string|boolean,
    // value: string|number,
    //onChange: (SelectChangeEvent) => void
}

export const formControlSx = {
    width: '100%',
    backgroundColor: 'var(--input-bg-color)',
    borderRadius: '.5rem',
    color: 'var(--input-color)',
    // ...(area && { gridArea: area }),
}

export const inputLabelSx = (theme: Theme) => ({
    color: 'var(--grey-221)',
    fontWeight: 300,
    fontSize: '1.6rem',
    paddingLeft: '10px',
    transform: 'translate(2.2rem, 12px) scale(1)',

    [theme.breakpoints.up('sm')]: {
        transform: 'translate(2rem, 1.9rem) scale(1)',
    },

    [`&.${inputLabelClasses.shrink}`]: {
        transform: 'translate(0, 3.5px) scale(1)',
        fontSize: '1.2rem',

        [theme.breakpoints.up('sm')]: {
            transform: 'translate(0, 3.5px) scale(1)',
            fontSize: '1.4rem',
        },
    },

    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem',
        transform: 'translate(2.2rem, 1.6rem) scale(1)',
    }

})

const FormControlStyled: FC<PropsWithChildren<Props>> = props => {
    const {
        id,
        label,
        // area,
        children,
        error,
    } = props;

    return (
        <FormControl
            variant="standard"
            sx={formControlSx}
            error={!!error}
        >
            {label ? (
                <InputLabel
                    htmlFor={id}
                    sx={inputLabelSx}
                >
                    {label}
                </InputLabel>
            ) : null}
            {children}
            {/*<FormHelperText error={error} />*/}
        </FormControl>
    )
}

export default FormControlStyled;
