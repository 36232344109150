import { styled } from '@mui/material/styles';

const Logo = styled('div', { name: 'Logo' })(({ theme }) => ({
    display: 'block',
    margin: '20px 0 0', // Dostosuj margines, aby ustawić logo na odpowiedniej wysokości
    textAlign: 'center', // Wyśrodkowanie tekstu
    fontFamily: 'Poiret One, sans-serif',
    fontSize: 'var(--logo-size)',
    lineHeight: '1',

    [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        margin: '40px 0 0',
        fontSize: 'calc(var(--logo-size) - 1.5rem)',
    },

    [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        fontSize: 'calc(var(--logo-size) - 0.5rem)',
    },

    [theme.breakpoints.up('lg')]: {
        fontSize: 'calc(var(--logo-size) + 0.5rem)',

    }
}));

export default Logo;
