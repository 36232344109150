import { FC, useEffect, useMemo } from 'react';
import { useField } from 'informed';

import { CommonProps } from '../../layout/LoanForm';
import Radio, { radioClasses } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, FormLabel } from '@mui/material';
import Box from '@mui/material/Box';

import icons from '../../assets/images/icons';
import formState, { loanReasonValue } from '../../model/formState';

const iconsMap = {
    [loanReasonValue.VACATIONS]: 'vacations',
    [loanReasonValue.CAR]: 'car',
    [loanReasonValue.RENOVATION]: 'renovation',
    [loanReasonValue.PAYMENTS]: 'payments',
    [loanReasonValue.EDUCATION]: 'education',
    [loanReasonValue.OTHER]: 'other',
}

const LoanReason: FC<CommonProps & { name: string }>  = props => {
    const { t, name } = props;

    const { fieldState, fieldApi, render, ref } = useField({
        type: 'checkbox',
        required: true,
        // validate: value => !!value,
        validateOn: 'change',
        name
    })

    // The field state ( contains value, error, dirty.. etc)
    const { value } = fieldState;

    // The field api ( allows you to control the field )
    const { setValue } = fieldApi;

    return (
        render(
            <RadioGroup
                ref={ref}
                name={name}
                defaultValue={formState.basicInformation.beforeAgreements.loan_reason}
                sx={theme => ({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, auto)',
                    gridTemplateRows: '1fr 1fr',
                    gap: '1.5rem 3rem',
                    margin: '1rem auto 3rem auto',
                    justifyContent: 'center',

                    [theme.breakpoints.up('sm')]: {
                        gap: '2rem 4rem',
                        margin: '3rem auto',
                    },

                    [theme.breakpoints.up('md')]: {
                        margin: '2rem auto',
                        gridTemplateColumns: 'repeat(6, auto)',
                        gridTemplateRows: '1fr',
                    },

                    [theme.breakpoints.up('lg')]: {
                       margin: '4rem 0 8rem',
                    }
                })}
                // value={value}
                onChange={({ target }) => {
                    setValue(target.value)
                }}
            >
                {Object.values(loanReasonValue).map((key, position) => (
                    <Box
                        key={position}
                        sx={theme => ({
                            position: 'relative',
                            width: '8rem',
                            height: '7.7rem',
                            padding: '1rem 1rem .7rem',

                            [theme.breakpoints.up('sm')]: {
                                width: '10.6rem',
                                height: '10.3rem',
                                pb: 0,
                            },

                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',

                            ['> div']: {
                                position: 'relative',
                                textAlign: 'center',
                                zIndex: 2,
                                pointerEvents: 'none',
                                lineHeight: '4rem',
                                height: '4rem',
                                width: '100%',

                                [theme.breakpoints.up('sm')]: {
                                    lineHeight: '6rem',
                                    height: '6rem',
                                },

                                ['img']: {
                                    filter: 'opacity(0.4)',
                                    verticalAlign: 'middle',

                                    [theme.breakpoints.up('sm')]: {
                                        width: '6.2rem',
                                        height: '5rem',
                                        objectFit: 'contain',
                                    },
                                }
                            },
                        })}
                    >
                        <Radio
                            id={key}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'var(--input-bg-color)', //'#1D4350',
                                borderRadius: '.4rem',

                                [`&.${radioClasses.checked}, :hover`]: {
                                    background: 'hsl(var(--base-color-h), calc(var(--base-color-s) - 4%), calc(var(--base-color-l) - 7%))',
                                    border: '1px solid var(--DFDFDF)',

                                    [`~ div`]: {
                                        ['img']: {
                                            filter: 'opacity(1)',
                                        }
                                    },

                                    ['~ label']: {
                                        color: 'var(--input-color)',
                                    },

                                },

                                [` > span`]: {
                                    visibility: 'hidden'
                                },


                            }}
                            value={key}
                            checked={key === value}
                        />
                        <div>
                            <img src={
                                // @ts-ignore
                                icons[iconsMap[key]]
                                // @ts-ignore
                            } alt={iconsMap[key]} />
                        </div>
                        <FormLabel
                            htmlFor={key}
                            sx={theme => ({
                                // color: 'var(--input-color)',
                                color: 'rgba(223, 223, 223, 0.40)',
                                fontSize: '1.4rem',
                                lineHeight: 1,
                                fontWeight: 500,
                                textAlign: 'center',

                                [theme.breakpoints.up('sm')]: {
                                    fontSize: '1.6rem',
                                    fontWeight: 400,
                                }
                            })}
                        >
                            {// @ts-ignore
                                t[iconsMap[key]]}
                        </FormLabel>
                    </Box>
                ))}
            </RadioGroup>
        )
    )
}

export default LoanReason;
