import MuiTooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material';

interface Props {
    text: string;
    variant?: string;
}
export const tooltipSx = (theme: Theme, variant?: string) => {
    let customStyles = {};

    if (variant !== 'custom') {
        customStyles = {
            marginRight: '6px',
            top: '-12px',
        };
    }

    return {
        fontSize: '1.5rem',
        width: '25px',
        height: '25px',
        border: '0.5px solid var(--grey-221)',
        color: 'var(--grey-221)',
        ...customStyles,
    };
};


const Tooltip = ({text, variant}:Props) => {
    return (
        <MuiTooltip
            title={text}
            placement="top-end"
            sx={(theme) => tooltipSx(theme, variant)}
            arrow
            enterTouchDelay={10}
            leaveTouchDelay={3500}
            PopperProps={{
                sx: {
                    [`.MuiTooltip-tooltip`]: {
                        backgroundColor: '#10262e',
                        color: 'var(--grey-221)',
                        fontSize: '1.2rem',
                    },

                    [`.MuiTooltip-arrow`]: {
                        color: '#10262e',
                    },
                },
            }}
        >
            <IconButton>?</IconButton>
        </MuiTooltip>
    );
}

export default Tooltip;
