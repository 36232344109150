import { Dispatch, FC, SetStateAction } from 'react';
import { StepNav } from './index';
import { useFormState, useScope, useScopedState } from 'informed';
import { CommonProps, FinishModalProps } from '../layout/LoanForm';
import { FinanceInformationStep, LoanFormValues, noIncomeEmploymentOptions } from '../model/formState';

interface Props {
    onNext?: Function,
    onBack?: Function,
}

const MaybeFinishNav: FC<CommonProps & Props> = props => {
    const {
        t,
        // open,
        onNext,
        onBack,
        // setFinishModalOpen
    } = props;

    const { valid, value: financeInformation } = useScopedState();

    const incomeSource = (financeInformation as FinanceInformationStep).income_source;

    const isEmployeeStepNeeded = !incomeSource || !(noIncomeEmploymentOptions.includes(incomeSource));

    return (
        <StepNav t={t} onNext={isEmployeeStepNeeded ? onNext : null} onBack={onBack} customNextText={isEmployeeStepNeeded ? '' : t.common.showLoanPropose} />
    )
}

export default MaybeFinishNav;