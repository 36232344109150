import { styled } from '@mui/material/styles';

const StyledSpinner = styled('div')`
  text-align: center;
  overflow: hidden;

  .circle {
    fill: none;
    stroke-width: 1;
    stroke-dasharray: 3, 500;
    stroke-dashoffset: -10;
    transform-origin: center center;
    animation: circle 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }

  .loader {
    margin-top: 3rem;
    transform-origin: center center;
    animation: rotate 2s linear infinite;

    ${({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        width: '30rem',
        height: '30rem',
        marginTop: '0',
      },

      [theme.breakpoints.up('md')]: {
        width: '35rem',
        height: '35rem',
      },

      [theme.breakpoints.up('lg')]: {
        width: '40rem',
        height: '40rem',
      },
      
    })};
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes circle {
    0% {
      stroke-dasharray: 3, 500;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 220, 500;
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dasharray: 220, 500;
      stroke-dashoffset: -310;
    }
  }
`


const Loader = sx => {
    return (
        <StyledSpinner sx={sx}>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="-50 -50 200 200" className="loader">
                <circle className="circle" cx="50" cy="50" r="50" stroke="#ddd" strokeWidth="1"/>
            </svg>
        </StyledSpinner>
    )
}

export default Loader;
