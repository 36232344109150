import { useState, useEffect, useCallback, useMemo } from 'react'

import finelfApi from '../model/finelfApi'
import { IAgreement } from '../model/IAgreement'
import {useFinsalesContext} from "../FinsalesContext";

const linksForReplace = {
  RULES_URL: 'http://prestamosi.es/terminos-de-uso/',
  PRIVACY_POLICY_URL: 'http://prestamosi.es/politica-de-privacidad/',
  SITE_NAME: 'Prestamosi',
};

type TlinksForReplaceKeys = (keyof typeof linksForReplace)[];

export type AgreementsOutput =  { agreements: IAgreement[], requiredAgreementsIds: number[] };

export function useAgreements(): AgreementsOutput {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agreements, setAgreements] = useState<IAgreement[]>([]);
  const {baseUrl, agreementsUri} = useFinsalesContext()
  // const [requiredAgreementsIds, setRequiredAgreements] = useState([]);

  const requiredAgreementsIds = useMemo<number[]>(() => (
    agreements
      .filter((agreement) => agreement.required)
      .map((agreement) => agreement.id)
  ), [agreements]);

  const linksForReplaceKeys = useMemo<TlinksForReplaceKeys>(() => (
    Object.keys(linksForReplace) as TlinksForReplaceKeys), []
  );

  const replaceLinkInAgreements = useCallback(async (agreements: IAgreement[]) => {
    return agreements.map((item) => {
      let newContent = item.content;

      if (linksForReplace) {
        linksForReplaceKeys.forEach(key => {
          const regExpSchema = new RegExp(key, 'g');
          newContent = newContent.replace(regExpSchema, linksForReplace[key]);
          return newContent;
        });
      }

      return { ...item, content: newContent };
    })
  }, [linksForReplaceKeys]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchAgreements = async () => {
      // setIsLoading(true);

      try {
        const uri = `${baseUrl}/${agreementsUri}`;
        const response = await fetch(uri, { signal: controller.signal });
        const data = await response.json();
        const agreements = await replaceLinkInAgreements(data);
        setAgreements(agreements);
      } catch (e) {
        console.log('fetchAgreements aborted! Catched!');
      }
      // setIsLoading(false);
    }

    if (agreements.length === 0) {
      fetchAgreements();
    }

    return () => {
      controller.abort();
      // console.log('fetchAgreements aborted! Cleanup!');
    }
  }, [/*isLoading,*/ agreements, replaceLinkInAgreements]);

  return { agreements, requiredAgreementsIds };
}
