import { TranslationJson } from './layout/LoanForm';

// @ts-ignore
export const validateRegex = (value, regex, message) => {
    if (value && !regex.test(value)) {
        return message
    }
}

// @ts-ignore
export const validateFunc = (value, fn, message) => {
    if (value && !fn(value)) {
        return message
    }
}

export const firstNameRe = /^[\p{Letter}]{2,}$/u
export const nameRe = /^[\p{Letter}]{2,}(?:(\s|-)[\p{Letter}\p{Mark}]+)?$/u

export const employeeStreetRe = /^([\p{Letter}\p{Mark}]{2,}(?:( |-|(\. ))[\p{Letter}\p{Mark}]+)?)*$/u

export const occupationRe= /^([\p{Letter}\p{Mark}]{2,}(?:( )[\p{Letter}\p{Mark}]+)?)*$/u

export const zipValidator = (t: TranslationJson, value: string) => validateRegex(value, /^[0-9]{2}-[0-9]{3}$/, t.common.valueError);
