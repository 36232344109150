import { FC } from 'react';
import { useField } from 'informed';

import { SxProps } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { formControlSx, inputLabelSx } from './FormControlStyled/FormControlStyled';
import { inputSx } from './TextField/TextField';
import { CommonProps } from '../layout/LoanForm';

import /*dayjs,*/ { Dayjs } from 'dayjs';

interface Props {
    id?: string,
    name: string,
    label?: string,

    placeholder?: string,
    value?: string|number,
    type?: string,
    readOnly?: boolean
    InputStyles?: SxProps
    maxLength?: string,
    required?: boolean
    autoComplete?: string,
    validate?: ((value: string) => string | void) | undefined
    minDate?: undefined|Dayjs
    maxDate?: undefined|Dayjs
}

const DateField: FC<CommonProps & Props> = props => {
    const {
        t,
        id: propsId,
        label,
        name,
        // type: propsType,
        readOnly,
        required,
        validate,
        minDate,
        maxDate,
    } = props;

    // const type = propsType || 'date';

    const { fieldState, fieldApi, render, ref } = useField({
        name,
        required,
        // @ts-ignore
        validate,
    })

    const { value, error, touched } = fieldState;
    const { setValue, setError, setTouched } = fieldApi;

    // @ts-ignore
    const handleChange = (newValue) => {
        const validationError = validate ? validate(newValue) : undefined;

        setValue(newValue);
        setTouched(true);
        setError(validationError);
    };

    const hasError = !!error && touched;

    const id = propsId || name;

    const handleDateError = (error) => {
        if (!error) return;

        switch (error) {
            case "invalidDate":
                setError(t.common.valueError);
                break;
            default:
                setError(t.common.valueError);
        }
    };

    return (
        render(
            <MuiDatePicker
                sx={formControlSx}
                minDate={minDate}
                maxDate={maxDate}
                onError={handleDateError}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        InputLabelProps: {
                            sx: inputLabelSx
                        },
                        InputProps: {
                            sx: theme => inputSx(theme),
                        },
                        FormHelperTextProps: {
                            sx: {
                                position: 'absolute',
                                bottom: '-1.8rem',
                                left: '1rem',
                            }
                        },
                        id,
                        autoComplete: 'off',
                        error: hasError,
                        // @ts-ignore
                        helperText: hasError ? error.toString() : ''
                    },
                    openPickerButton: {
                        sx: {
                            // background: 'red'
                            color: 'var(--input-color)',
                            mr: 0,
                            mt: '-1.1rem',
                        },

                    },
                    openPickerIcon: {
                        sx: {
                            width: '2.5rem',
                            height: '2.5rem',
                        }
                    },
                    desktopPaper: {
                        sx: {
                            background: 'var(--modal-bg-color)',
                            color: 'var(--input-color) !important',

                            ['span, div, button']: {
                                color: 'var(--input-color) !important',

                                ['&[disabled]']: {
                                    color: 'rgba(221, 221, 221, 0.60) !important',
                                },
                            }
                        }
                    },
                    mobilePaper: {
                        sx: {
                            background: 'var(--modal-bg-color)',
                            color: 'var(--input-color) !important',

                            ['span, div, button']: {
                                color: 'var(--input-color) !important',

                                ['&[disabled]']: {
                                    color: 'rgba(221, 221, 221, 0.60) !important',
                                },
                            }
                        }
                    }
                }}
                inputRef={ref}
                label={label || ''}
                // @ts-ignore
                required={required}
                value={value}
                // timezone="Europe/Warsaw"
                onChange={value => handleChange(value)}
                format="YYYY-MM-DD"
                {...{ readOnly }}
            />
        )
    )
}

export default DateField;
