import { FC, useEffect, useState } from 'react';
import { useField } from 'informed';
import tinygradient from 'tinygradient';

import Input from '@mui/material/Input';
import Slider, { sliderClasses } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import { CommonProps } from '../../layout/LoanForm';
import { FormControlStyled } from '../../components';
import { inputSx } from '../TextField/TextField';
import { useFinsalesContext } from "../../FinsalesContext";

const Root = styled('div', { name: 'funedaAmountCombinedField' })(({ theme }) => ({
    position: 'relative',
    marginBottom: '1.2rem',
    gridArea: 'amount',

    [theme.breakpoints.up('sm')]: {
        marginBottom: 0
    }
}));

const SliderPositioner = styled('div', { name: 'funedaSliderPositioner' })(({ theme }) => ({
    position: 'absolute',
    bottom: '-2rem',

    ['@media (pointer: coarse)']: {
        bottom: '-2.7rem',
    },

    width: '100%',

    [theme.breakpoints.up('md')]: {
        bottom: '-2.4rem',
    },

    [theme.breakpoints.up('md') + ' and (pointer: coarse)']: {
        bottom: '-2.8rem',
    },

    [theme.breakpoints.up('lg')]: {
        bottom: '-2.4rem',
    },

    [theme.breakpoints.up('lg') + ' and (pointer: coarse)']: {
        bottom: '-2.7rem',
    },
}));

const startC = 'hsl(var(--base-color-h), calc(var(--base-color-s) - 31%), calc(var(--base-color-l) + 14%))';
const finishC = '#DFDFDF';
const sliderTrackCssVar = '--Slider-track-gradient-at';

const gradient = tinygradient(startC, finishC);

const FunedaSlider = styled(Slider)(({ theme }) => ({
    [sliderTrackCssVar]: startC,

    [`.${sliderClasses.track}`]: {
        border: 0,
        background: `linear-gradient(.25turn, ${startC} 0%, var(${sliderTrackCssVar}) 100%)`
    },
    [`.${sliderClasses.thumb}`]: {
        background: 'var(--Slider-track-gradient-at)',
        width: '1.5rem',
        height: '1.5rem',

        [theme.breakpoints.up('sm')]: {
            width: '2rem',
            height: '2rem',
        },

        '&:before': {
            background: 'hsl(var(--base-color-h), calc(var(--base-color-s) - 4%), calc(var(--base-color-l) - 7%))',
            width: '.9rem',
            height: '.9rem',

            [theme.breakpoints.up('sm')]: {
                width: '1.4rem',
                height: '1.4rem',
            },
        }
    }
}));

const AmountCombinedField: FC<CommonProps> = props => {
    const { maxAmount } = useFinsalesContext();
    const { t, tabindex } = props;

    const name = 'amount';

    const { fieldState, fieldApi, render, ref } = useField({
        name
    });

    const { value } = fieldState as { value: number };

    const { setValue } = fieldApi;

    const [inputValue, setInputValue] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);

    const minAmount = 100;
    const step = 100;

    const formatValue = (val: number | null | undefined) => {
        if (val == null || isNaN(val)) return '';
        return val.toLocaleString('pl-PL', { useGrouping: true, maximumFractionDigits: 0 }) + ' zł';
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleBlur = () => {
        processInputValue();
        setIsInputFocused(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            processInputValue();
            setIsInputFocused(false);
        }
    };

    const processInputValue = () => {
        let newValue = parseInt(inputValue.replace(/\s/g, '').replace(/[^\d]/g, ''), 10);
        if (isNaN(newValue)) {
            newValue = minAmount; // Set to minimum amount if input is invalid
        }

        // Round to nearest step
        newValue = Math.round(newValue / step) * step;

        // Clamp between min and max
        if (newValue < minAmount) newValue = minAmount;
        if (newValue > maxAmount) newValue = maxAmount;

        // Update the field value
        setValue(newValue);

        // Update the inputValue to formatted value
        setInputValue(formatValue(newValue));
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    useEffect(() => {
        if (!isInputFocused) {
            if (value != null) {
                setInputValue(formatValue(value));
            } else {
                setInputValue('');
            }
        }
    }, [value, isInputFocused]);

    useEffect(() => {
        if (ref?.current) {
            const slider = ref?.current;

            if (slider) {
                slider.style?.setProperty(
                    sliderTrackCssVar,
                    gradient.rgbAt((value / maxAmount) || 0).toHexString()
                );
            }
        }
    }, [value, ref, maxAmount]);

    return (
        <Root>
            {render(
                <>
                    <FormControlStyled id="sliderOutput" label={t.basicStep.amount}>
                        <Input
                            name={name}
                            id="sliderOutput"
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            sx={theme => inputSx(theme)}
                            inputProps={{
                                tabIndex: Number('-1'),
                                inputMode: 'numeric'
                            }}
                        />
                    </FormControlStyled>
                    <SliderPositioner>
                        <FunedaSlider
                            ref={ref}
                            min={minAmount}
                            step={step}
                            max={maxAmount}
                            value={value || minAmount}
                            onChange={handleSliderChange}
                            aria-label="amount slider"
                            tabIndex={Number(tabindex)}
                        />
                    </SliderPositioner>
                </>
            )}
        </Root>
    );
};

export default AmountCombinedField;
