import { FC } from 'react';
import { useField } from 'informed';

import MuiTextField from '@mui/material/TextField';
import { inputClasses } from '@mui/material/Input';
import { SxProps, Theme } from '@mui/material';
import { formControlSx, inputLabelSx } from '../FormControlStyled/FormControlStyled';
import Tooltip from '../Tooltip/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
    id?: string;
    name: string;
    label?: string;
    placeholder?: string;
    tooltipText?: string;
    value?: string|number;
    type?: string;
    readOnly?: boolean;
    sxModifier?: string;
    FormHelperTextSx?: SxProps;
    maxLength?: string;
    minLength?: string;
    required?: boolean;
    maskOnBlur?: boolean;
    autoComplete?: string;
    validate?: ((value: string, values: Record<string, unknown>) => string | void) | undefined;
    asyncValidate?: ((value: string) => string | void) | undefined;
    validateWhen?: string[];
    validateDeps?: string[];
    pattern?: string|undefined;
    formatter?: ((value: any, ogValue: any) => any) | string | (string | RegExp)[];
    mask?: ((value: any, ogValue: any) => any) | string;
    parser?: (value: any) => number | undefined;
    inputMode?: "text" | "search" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;
    tabindex?: string;
    // validateOn?: "change" | "blur" | "change-blur" | "change-submit" | "blur-submit" | "submit" | undefined,
}

export const inputSx = (theme: Theme, sxModifier: string = '') => ({
    color: 'inherit',
    padding: '0 0 0 3rem',
    fontSize: '1.6rem',
    borderBottomWidth: 0,

    ['&:hover']: {
        ['&:before']: {
            border: 'none !important'
        },
    },

    ['&:before']: {
        borderBottomWidth: 0,
    },

    ['&:after']: {
        borderRadius: '1rem',
    },

    [`.${inputClasses.input}`]: {
        height: '1.8rem',
        padding: '.4rem 0 .8rem',

        [theme.breakpoints.up('sm')]: {
            padding: '1.2rem 0 1.4rem',
        },
    },

    [theme.breakpoints.up('sm')]: {
        fontSize: '2rem',
    },

    ...(sxModifier === 'bankAccountPrefix' && {
        paddingLeft: '.8rem',
        marginTop: '1.6rem',

        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
            paddingLeft: '1.5rem',
        },
    }),

    ...(sxModifier === 'phonePrefix' && { paddingLeft: '.8rem', marginTop: '1.6rem' })
});

const TextField: FC<Props> = props => {
    const {
        id: propsId,
        label,
        placeholder,
        tooltipText,
        name,
        value,
        type: propsType,
        readOnly,
        sxModifier,
        FormHelperTextSx,
        maxLength,
        minLength,
        autoComplete,
        required,
        validate,
        asyncValidate,
        validateWhen,
        validateDeps,
        pattern,
        formatter,
        parser,
        inputMode,
        mask,
        maskOnBlur,
        tabindex,
    } = props;

    const type = propsType || 'text';

    /*const validateOn = 'change';
    const showErrorIfError = true;*/

    const { render, ref, informed, fieldApi: { setValue }, fieldState: { error, touched } } = useField({
        type,
        name,
        required,
        //@ts-ignore
        validate,
        asyncValidate,
        validateWhen,
        formatter,
        parser,
        mask,
        maskOnBlur,
        validateDeps,
        // keepStateIfRelevant: true,
    });

    const id = propsId || name;

    const hasErrorAndTouched = !!error && touched;

    // @ts-ignore
    return (
        render(
            <MuiTextField
                inputRef={ref}
                variant="standard"
                id={id}
                label={label || (hasErrorAndTouched ? '*' : '')}
                defaultValue={value}
                error={hasErrorAndTouched}
                helperText={hasErrorAndTouched ? error.toString() : ''}
                type={type}
                {...(placeholder && { placeholder })}
                {...(required && { required })}
                {...informed}
                onChange={e => {
                    setValue(e.target.value, e);

                    /*if (e.target.validationMessage) {
                        setError(e.target.validationMessage, e)
                    }*/
                }}
                /*onBlur={e => {
                    console.log({ e })
                    setTouched(true, e);
                    if (e.target.validationMessage) {
                        setError(e.target.validationMessage, e)
                    }
                }}*/
                inputProps={{
                    ...(maxLength && { maxLength }),
                    ...(minLength && { minLength }),
                    ...(autoComplete && { autoComplete }),
                    ...(pattern && { pattern }),
                    ...(inputMode && { inputMode }),
                    ...(tabindex && { tabIndex: Number(tabindex) }),
                }}
                InputLabelProps={{
                    sx: inputLabelSx
                }}
                InputProps={{
                    sx: theme => inputSx(theme, sxModifier),
                    ...{ readOnly },
                    ...(tooltipText && {endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip text={tooltipText}/>
                            </InputAdornment>
                        )}),
                }}
                FormHelperTextProps={{
                    sx: {
                        position: 'absolute',
                        bottom: '-1.8rem',
                        left: '1rem',
                        ...FormHelperTextSx,
                    }
                }}
                sx={formControlSx}
            />
        )

        /*<FormControlStyled id={id} label={label || ''}>
            {render(
                <Input
                    id={id}
                    error={!!error as boolean}
                    defaultValue={value}
                    readOnly={!!readOnly}
                    type={type}
                    {...(placeholder && { placeholder})}
                    {...informed}
                    inputProps={{
                        ...(maxLength && { maxLength }),
                        ...(autoComplete && { autoComplete }),
                    }}
                    sx={theme => inputSx(theme, InputStyles || {})}
                        // ...inputSx(theme),
                        //...(inputStyles || {})

                />
            )}
        </FormControlStyled>*/
    )
}

export default TextField;
