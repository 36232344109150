import { FC  } from 'react';
import { styled } from '@mui/material/styles';
import Button, { buttonClasses } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material';
import { CommonProps } from '../../layout/LoanForm';
import { useFormState } from 'informed';


const Root = styled('div', { name: 'funedaStepNav' })(({ theme }) => ({
    maxWidth: '38rem',
    margin: '2rem auto 4.8rem', //1rem dla bezpieczeństwa
}));

const Prev = styled('button', { name: 'funedaPrev' })(({ theme }) => ({
    textAlign: 'center',
    border: 'none',
    background: 'none',
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    fontWeight: 300,
    color: 'var(--input-color)',
    cursor: 'pointer',

    [`&[disabled]`]: {
        opacity: 0.5
    }
}));

interface Props {
    onNext?: Function|null,
    onBack?: Function,
    // nextDisabled: boolean,
    // backDisabled: boolean,
    hideBack?: boolean,
    sx?: (theme: Theme) => object,
    customNextText?: string,
    disabled?: boolean,
}

const StepNav: FC<CommonProps & Props> = props => {
    const {
        t,
        onNext,
        onBack,
        hideBack,
        sx ,
        customNextText,
        disabled,
    } = props;
    const { valid } = useFormState();

    const handleNext = () => {
        onNext?.();
    }

    const handleBack = () => {
        onBack?.();
    }

    return (
        <Root sx={theme => sx ? sx(theme) : null }>
            <Stack
                direction={{ xs: 'row' }}
                justifyContent="center"
                sx={{
                    flexWrap: 'wrap',
                    gap: '2rem',
                }}
            >
                <Button
                    variant="text"
                    type={onNext ? 'button' : 'submit'}
                    onClick={handleNext}
                    disabled={!valid || disabled || false}
                    sx={theme => ({
                        fontSize: '2rem',
                        padding: '1.2rem 1.6rem',
                        fontWeight: 400,
                        lineHeight: 1,
                        textTransform: 'none',
                        borderRadius: '1rem',
                        border: '1px solid var(--input-color)',
                        backgroundColor: 'rgba(106, 106, 106, 0.80)',
                        color: 'var(--input-color)',

                        [theme.breakpoints.up('sm')]: {
                            width: '100%',
                            fontSize: '2.6rem',
                            padding: '1.1rem 0.8rem',
                        },

                        [`&:hover`]: {
                            backgroundColor: 'rgba(106, 106, 106, 0.80)',
                        },
                        [`&.${buttonClasses.disabled}`]: {
                            color: 'var(--input-color)',

                            opacity: 0.5
                        },
                    })}
                >{customNextText || t.common.next}</Button>
                {!hideBack ? (
                    <Prev
                        role="button"
                        onClick={handleBack}
                    >
                        {t.common.back}
                    </Prev>
                ) : null}
            </Stack>
        </Root>
    )
}

export default StepNav;
