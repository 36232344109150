import { styled } from '@mui/material';
import { StyledTags } from '@emotion/styled';

const Title = (tag: keyof StyledTags) => styled(tag, { name: 'funedaTitleText' })(({ theme }) => ({
    marginTop: 20,
    marginBottom: 0,
    fontSize: '1.7rem',

    [theme.breakpoints.up('sm')]: {
        marginTop: 30,
        marginLeft: 22,
        fontSize: '2.4rem',
        lineHeight: '2.4rem',
    },

    [theme.breakpoints.up('md')]: {
        marginTop: 30,
        marginLeft: 22,
    },

    [theme.breakpoints.up('lg')]: {
        marginTop: 80,
        marginLeft: 67
    },

    fontWeight: 400,
    lineHeight: 1.1,
    color: 'var(--input-color)'
}));

const H1 = Title('h1');
const H2 = Title('h2');

export { H1, H2 }
