import { FC, useMemo } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { useField } from 'informed';
import { AgreementsOutput } from '../../hooks/useAgreements';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { FormControlLabel, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FieldMap } from '../../model/FieldMap';

import { AgreementItemRecursive, LabelToggle } from '../index';
import { CommonProps } from '../../layout/LoanForm';
import { AgreementItem, FunedaCheckbox } from './AgreementItemRecursive';

const AgreementsCheckboxesSection: FC<CommonProps & AgreementsOutput> = props => {
    const {
        t,
        agreements,
        requiredAgreementsIds
    } = props;

    const rootAgreements = useMemo(() => agreements.filter(({ parentId }) => !parentId), [agreements]);
    const childrenAgreementsParentIds = useMemo(() => agreements.filter(({ parentId }) => parentId).map(({ parentId }) => parentId), [agreements]);
    // @ts-ignore
    const uniqueChildrenAgreementsParentIds = useMemo(() => [...new Set(childrenAgreementsParentIds)], [childrenAgreementsParentIds]);
    const uniqueChildrenAgreements = useMemo(() => uniqueChildrenAgreementsParentIds.reduce((acc, pId) => {
        return {
            ...acc,
            [`${pId}`]: agreements.filter(({ parentId }) => parentId === pId),
        }
    }, {}), [uniqueChildrenAgreementsParentIds, agreements])

    const { fieldState, fieldApi, render, ref } = useField(
        {
            name: FieldMap.AGREEMENTS,
            validate: (agreementsChecked, values) => {
                if (
                    (agreementsChecked as number[]).length > 0
                    && !requiredAgreementsIds.every(id => (agreementsChecked as number[]).includes(id))
                ) {
                    return t.basicStep.checkRequired;
                }
            },
            validateOn: 'change',
            required: true
        }
    )

    const { value: agreementsChecked, dirty, error } = fieldState as { value: number[], [key: string]: any };

    const { setValue } = fieldApi;

    const allChecked = agreements.every(({ id }) => agreementsChecked.includes(id));

    const handleChange = (id: number) => {
        const agreementIdsToToggle = [id];

        const addChildrenIds = (parentId: number) => {
            const children = uniqueChildrenAgreements[parentId];
            if (children && children.length) {
                children.forEach(({ id }) => {
                    agreementIdsToToggle.push(id);
                    addChildrenIds(id);
                });
            }
        };

        addChildrenIds(id);

        const newAgreementsChecked = agreementsChecked.includes(id)
            ? agreementsChecked.filter(identifier => !agreementIdsToToggle.includes(identifier))
            : [...agreementsChecked, ...agreementIdsToToggle.filter(childId => !agreementsChecked.includes(childId))];

        setValue(newAgreementsChecked);
    };

    const handleToggleAll = (checked: boolean) => {
        setValue(checked ? agreements.map(({ id }) => id) : []);
    };

    return (
        <FormGroup
            sx={theme => ({
                gap: '.7rem',

                [theme.breakpoints.up('sm')]: {
                    gap: '3rem',
                },
            })}
        >
            <AgreementItem sx={{ mb: '2rem' }}>
                <div className='agree-subrow'>
                    <FunedaCheckbox
                        id="selectAll"
                        name="selectAll"
                        checked={allChecked}
                        indeterminate={!allChecked && agreementsChecked.length > 0}
                        onChange={({ target }) => handleToggleAll(target.checked)}
                    />
                    <LabelToggle
                        targetId="selectAll"
                        t={t}
                        content={t.basicStep.agreementAcceptAll}
                        bold
                    />
                </div>
            </AgreementItem>
            {render(
                rootAgreements.map((agreement, index) => {
                    return (
                        <AgreementItemRecursive
                            t={t}
                            key={index}
                            agreement={agreement}
                            agreementsChecked={agreementsChecked}
                            requiredAgreementsIds={requiredAgreementsIds}
                            uniqueChildrenAgreements={uniqueChildrenAgreements}
                            handleChange={handleChange}
                            dirty={dirty}
                            error={error}
                        />
                    )}
                )
            )}
        </FormGroup>
    )
}

export default AgreementsCheckboxesSection;
