import { FieldMap } from '../../model/FieldMap';
import { LabelToggle } from '../index';
import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { useState } from 'react';
import { margin } from '@mui/system';

export const AgreementItem = styled('div', { name: 'funedaAgreementItem' })<{ depth?: number }>(({ theme, depth }) => ({
    width: '100%',

    [theme.breakpoints.up('sm')]: {
        marginLeft: depth ? `${depth + 1}rem` : 0
    },

    [`.agree-subrow`]: {
        display: 'flex',
        flexGrow: 1,
        gap: '1rem',
        width: '100%',
    },

    label: {
        display: 'block',
        flexWrap: 'wrap',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
            lineHeight: '1.1',
        },

        a: {
            color: 'inherit',
            fontWeight: 'bolder'
        }

    },
    [`span[role="button"]`]: {
        display: 'block',
        textAlign: 'right',
        paddingRight: '3rem',
    },
}));

export const FunedaCheckbox = styled(Checkbox, { name: 'funedaCheckbox' })(({ theme }) => ({
    '--checkbox-size': '1.8rem',

    [theme.breakpoints.up('sm')]: {
        '--checkbox-size': '2.5rem',
    },

    // background: 'red',
    p: 0,
    pr: '1rem',
    alignItems: 'flex-start',

    [`.${svgIconClasses.root}`]: {
        borderRadius: '.4rem',
        width: 'var(--checkbox-size)',
        height: 'var(--checkbox-size)',
        fontSize: 'var(--checkbox-size)',
        lineHeight: 'var(--checkbox-size)',
    },

    color: 'var(--input-color)',

    [`&.${checkboxClasses.checked}`]: {
        color: 'var(--input-color)',
    },
}));

const AgreeGroupToggleWrapper = styled('div', { name: 'funedaAgreeGroupToggleWrapper' })`
  text-align: right;
`;


const AgreeGroupToggle = styled('button', { name: 'funedaAgreeGroupToggle' })(({ theme }) => ({
    width: '8rem',
    border: 0,
    background: 'none',
    display: 'inline',
    color: 'var(--input-color)',
    paddingRight: '3rem',
    fontWeight: 'bold',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem'
    },
}));

const AgreementItemRecursive = props => {
    const {
        t,
        agreement, //: propsAgreement,
        agreementsChecked,
        requiredAgreementsIds,
        uniqueChildrenAgreements,
        handleChange,
        dirty,
        error,
        depth = 0,
    } = props;

    const [showAgreeGroup, setShowAgreeGroup] = useState<boolean>(false)

    // const agreement = propsAgreement === 2 ? { ...propsAgreement, id: 3 } : propsAgreement;

    const childrens = uniqueChildrenAgreements[agreement.id];
    // console.log({ uniqueChildrenAgreements, childrens, id: agreement.id })

    const shouldShowChildrens = depth < 3 && !!childrens;

    return (
        <>
            <AgreementItem depth={depth}>
                <div className='agree-subrow'>
                    <div>
                        <FunedaCheckbox
                            id={FieldMap.AGREEMENTS + agreement.id}
                            name={FieldMap.AGREEMENTS}
                            value={agreement.id}
                            checked={agreementsChecked.includes(agreement.id)}
                            onChange={() => handleChange(agreement.id)}
                        />
                    </div>
                    <LabelToggle
                        targetId={FieldMap.AGREEMENTS + agreement.id}
                        t={t}
                        content={agreement.content}
                        required={requiredAgreementsIds.includes(agreement.id)}
                    />
                    {shouldShowChildrens && (
                        <AgreeGroupToggleWrapper sx={theme => ({
                            display: 'none',

                            [theme.breakpoints.up('sm')]: {
                                display: 'block',
                                alignSelf: 'center',
                            },
                        })}>
                            <AgreeGroupToggle
                                type='button'
                                onClick={() => setShowAgreeGroup(!showAgreeGroup)}
                            >
                                {t.basicStep[showAgreeGroup ? 'hide' : 'show']}
                            </AgreeGroupToggle>
                        </AgreeGroupToggleWrapper>
                    )}
                </div>
                <div className='agree-subrow'>
                    {dirty
                    && requiredAgreementsIds.includes(agreement.id)
                    && !agreementsChecked.includes(agreement.id)
                        ? <FormHelperText sx={theme => ({
                            color: 'red',
                            pl: /*depth ? `${depth + 5}rem` :*/ '5rem',

                            [theme.breakpoints.up('sm')]: {
                                fontSize: '1.8rem',
                                lineHeight: 1.1,
                            },
                        })}
                        >
                            {error}
                        </FormHelperText> : null
                    }
                </div>
            </AgreementItem>
            {shouldShowChildrens && (
                <AgreeGroupToggleWrapper sx={theme => ({
                    [theme.breakpoints.up('sm')]: {
                        display: 'none',
                    },
                })}>
                    <AgreeGroupToggle
                        type='button'
                        onClick={() => setShowAgreeGroup(!showAgreeGroup)}
                    >
                        {t.basicStep[showAgreeGroup ? 'hide' : 'show']}
                    </AgreeGroupToggle>
                </AgreeGroupToggleWrapper>
            )}
            {showAgreeGroup && shouldShowChildrens && childrens.map((_agreement, index) => {
                return (
                    <AgreementItemRecursive
                        t={t}
                        key={_agreement.id + '_' + index}
                        agreement={_agreement}
                        agreementsChecked={agreementsChecked}
                        requiredAgreementsIds={requiredAgreementsIds}
                        uniqueChildrenAgreements={uniqueChildrenAgreements}
                        handleChange={handleChange}
                        dirty={dirty}
                        error={error}
                        depth={depth + 1}
                    />
                )
            })
            }
        </>
    )
}

export default AgreementItemRecursive;