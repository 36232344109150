import car from './car.svg';
import education from './education.svg';
import payments from './payments.svg';
import vacations from './vacations.svg';
import renovation from './renovation.svg';
import other from './other.svg';

export default {
    car,
    education,
    payments,
    vacations,
    renovation,
    other
}