import { styled } from '@mui/material/styles';

const Divider = styled('div', { name: 'funedaDivider' })(({ theme }) => ({
    // marginTop: '28px',
    marginTop: '1.5rem',
    height: 1,
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%)',

    [theme.breakpoints.up('sm')]: {
        marginTop: '4rem',
        marginBottom: '1rem'
    },

    [theme.breakpoints.up('lg')]: {
        marginBottom: 0
    }
}));

export default Divider;