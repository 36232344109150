import { Container as MuiContainer, styled } from '@mui/material';

const Container = styled(MuiContainer, { name: "funedaContainer" })(({ theme }) => ({
    padding: '0 30px',

    [theme.breakpoints.up('sm')]: {
        // padding: '0 40px',
        padding: 0,
        width: theme.breakpoints.values['sm'] - (2 * 40)
    },

    [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values['md'] - (2 * 40)
    },

    [theme.breakpoints.up('lg')]: {
        width: theme.breakpoints.values['lg'] - (2 * 10)
    }


}));

export default Container